// ----- Progress Tracker Animations -----
// Animation show after click using pure CSS based on a technique - https://ghinda.net/article/css-ripple-material-design/ and the Material design ripple - https://getmdl.io/components/#buttons-section

// Ripple elements mixin
@mixin ripple-element() {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 30;
  background: $ripple-color;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0); // Initial state, should hide the effect when not animating
  visibility: hidden; // Hides element so it doesnt animate on page load
}

@mixin ripple-element-active() {
  visibility: visible; // Shows element when it is focused
}

// Animation Keyframes
@keyframes scale-up {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }

  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}



// ----- Ripple effect when marker is clicked -----
.anim-ripple, .anim-ripple-large, .anim-ripple-splash {

  .progress-marker::before {
    @include ripple-element();
  }

  :not(:active) .progress-marker::before {
    animation: scale-up $animation-duration ease-out;
  }

  :focus .progress-marker::before {
    @include ripple-element-active();
  }

}

// ----- Large ripple which expands outside of the bounds of marker -----
.anim-ripple-large {

  .progress-marker::before {
    width: 200%;
    height: 200%;
  }

}

// ----- Large ripple with splash -----
.anim-ripple-splash {

  .progress-marker::before {
    width: 200%;
    height: 200%;
    box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.35);
  }

}

// ----- Double ripple effect, similar to an actual water ripple -----
.anim-ripple-double {

  .progress-marker {
    &::before, &::after {
      @include ripple-element();
      background: none;
      border: 3px solid $ripple-color;
    }
  }

  :not(:active) .progress-marker {
    &::before, &::after {
      animation: scale-up $animation-duration ease-out 0s;
    }
    &::after {
      animation-delay: $animation-duration/2;
    }
  }

  :focus .progress-marker {
    &::before, &::after {
      @include ripple-element-active();
    }
  }

}

// ----- Fill up the path between markers when it becomes active -----
.anim-path {

  .progress-step {

    &::after {
      background-image: linear-gradient(to right, $progress-color-grey-light 50%, $progress-color-grey 50%);
      background-size: 200% 100%;
      background-position: 0% 100%;
      transition: background-position $animation-duration ease-out;
    }

    &.is-complete::after {
      background-position: -100% 100%;
    }

  }

}
