.progress-tracker {
  display: flex;
  margin: 40px auto;
  padding: 0;
  list-style: none;
}

.progress-step {
  display: block;
  position: relative;
  flex: 1 1 0%;
  margin: 0;
  padding: 0;
  min-width: 28px;
}

.progress-step:last-child {
  flex-grow: 0;
}

.progress-step:not(:last-child)::after {
  content: '';
  display: block;
  position: absolute;
  z-index: -10;
  top: 12px;
  bottom: 12px;
  right: -14px;
  width: 100%;
  height: 4px;
  transition: background-color 0.3s;
}

.progress-step.is-active .progress-title {
  font-weight: 400;
}

.progress-step > a {
  display: block;
}

.progress-marker {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 20;
  width: 28px;
  height: 28px;
  padding-bottom: 2px;
  color: #fff;
  font-weight: 400;
  border: 2px solid transparent;
  border-radius: 50%;
  transition: background-color, border-color;
  transition-duration: 0.3s;
}

.progress-text {
  display: block;
  padding: 14px 9.3333333333px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress-title {
  margin-top: 0;
}

.progress-step .progress-marker {
  color: #fff;
  background-color: #b6b6b6;
}

.progress-step::after {
  background-color: #b6b6b6;
}

.progress-step .progress-text, .progress-step .progress-step > a .progress-text {
  color: #333333;
}

.progress-step.is-active .progress-marker {
  background-color: #2196F3;
}

.progress-step.is-complete .progress-marker {
  background-color: #1976D2;
}

.progress-step.is-complete::after {
  background-color: #868686;
}

.progress-step:hover .progress-marker {
  background-color: #56ADF5;
}

.progress-tracker--center .progress-step {
  text-align: center;
}

.progress-tracker--center .progress-step:last-child {
  flex-grow: 1;
}

.progress-tracker--center .progress-step::after {
  right: -50%;
}

.progress-tracker--center .progress-marker {
  margin-left: auto;
  margin-right: auto;
}

.progress-tracker--right .progress-step {
  text-align: right;
}

.progress-tracker--right .progress-step:last-child {
  flex-grow: 1;
}

.progress-tracker--right .progress-step::after {
  right: calc(-100% + 14px);
}

.progress-tracker--right .progress-marker {
  margin-left: auto;
}

.progress-tracker--border {
  padding: 5px;
  border: 2px solid #868686;
  border-radius: 38px;
}

.progress-tracker--spaced .progress-step::after {
  width: calc(100% - 48px);
  margin-right: 24px;
}

.progress-tracker--word {
  padding-right: 38.6666666667px;
  overflow: hidden;
}

.progress-tracker--word .progress-text {
  display: inline-block;
  white-space: nowrap;
}

.progress-tracker--word .progress-title {
  margin: 0;
}

.progress-tracker--word-center {
  padding-right: 38.6666666667px;
  padding-left: 38.6666666667px;
}

.progress-tracker--word-center .progress-text {
  padding-right: 0;
  padding-left: 0;
  transform: translateX(calc(-50% + 14px));
}

.progress-tracker--word-right {
  padding-right: 0;
  padding-left: 38.6666666667px;
}

.progress-tracker--word-right .progress-text {
  padding-left: 0;
  transform: translateX(calc(-100% + 28px));
}

.progress-tracker--text .progress-step:last-child {
  flex-grow: 1;
}

.progress-tracker--text-top .progress-step::after {
  top: auto;
}

.progress-tracker--text-top .progress-text {
  height: 100%;
}

.progress-tracker--text-top .progress-marker {
  bottom: 28px;
}

.progress-tracker--text-inline .progress-step {
  display: flex;
}

.progress-tracker--text-inline .progress-text {
  position: relative;
  z-index: 30;
  max-width: 70%;
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;
}

.progress-tracker--text-inline .progress-title {
  margin: 0;
}

.progress-tracker--square .progress-step {
  padding-top: 0;
}

.progress-tracker--square .progress-marker {
  transform: scaleX(0.33) translateY(-12px);
  border-radius: 0;
}

@media (max-width: 399px) {
  .progress-tracker-mobile {
    overflow-x: auto;
  }
  .progress-tracker-mobile .progress-tracker {
    min-width: 200%;
  }
}

.progress-tracker--vertical {
  flex-direction: column;
}

.progress-tracker--vertical .progress-step {
  flex: 1 1 auto;
}

.progress-tracker--vertical .progress-step::after {
  right: auto;
  top: 14px;
  left: 12px;
  width: 4px;
  height: 100%;
}

.progress-tracker--vertical .progress-marker {
  position: absolute;
  left: 0;
}

.progress-tracker--vertical .progress-text {
  padding-top: 7px;
  padding-left: 42px;
}

.progress-tracker--vertical .progress-step:not(:last-child) .progress-text {
  padding-bottom: 42px;
}

@keyframes scale-up {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}

.anim-ripple .progress-marker::before, .anim-ripple-large .progress-marker::before, .anim-ripple-splash .progress-marker::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 30;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  visibility: hidden;
}

.anim-ripple :not(:active) .progress-marker::before, .anim-ripple-large :not(:active) .progress-marker::before, .anim-ripple-splash :not(:active) .progress-marker::before {
  animation: scale-up 0.3s ease-out;
}

.anim-ripple :focus .progress-marker::before, .anim-ripple-large :focus .progress-marker::before, .anim-ripple-splash :focus .progress-marker::before {
  visibility: visible;
}

.anim-ripple-large .progress-marker::before {
  width: 200%;
  height: 200%;
}

.anim-ripple-splash .progress-marker::before {
  width: 200%;
  height: 200%;
  box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.35);
}

.anim-ripple-double .progress-marker::before, .anim-ripple-double .progress-marker::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 30;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  visibility: hidden;
  background: none;
  border: 3px solid rgba(0, 0, 0, 0.3);
}

.anim-ripple-double :not(:active) .progress-marker::before, .anim-ripple-double :not(:active) .progress-marker::after {
  animation: scale-up 0.3s ease-out 0s;
}

.anim-ripple-double :not(:active) .progress-marker::after {
  animation-delay: 0.15s;
}

.anim-ripple-double :focus .progress-marker::before, .anim-ripple-double :focus .progress-marker::after {
  visibility: visible;
}

.anim-path .progress-step::after {
  background-image: linear-gradient(to right, #b6b6b6 50%, #868686 50%);
  background-size: 200% 100%;
  background-position: 0% 100%;
  transition: background-position 0.3s ease-out;
}

.anim-path .progress-step.is-complete::after {
  background-position: -100% 100%;
}
